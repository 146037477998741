<template>
  <div
    v-if="isActive"
    :id="anchor"
    v-intersect="{
      handler: onIntersect,
      options: {
        threshold: [0, 0.5, 1.0],
      },
    }"
    class="cms-element-bs-image-text base-responsive"
    :class="classes"
    :style="mediaStyle"
  >
    <div class="base-responsive__sizer" :style="responsiveSizeStyle"></div>
    <div class="base-responsive__content d-flex">
      <cms-util-link
        :url="imageLink"
        :class="{ 'cms-element-bs-image-text__link': !mediaUrl }"
        class="w-full"
      >
        <video
          v-if="isVideo && mediaUrl"
          loop
          autoplay
          playsinline
          muted
          class="cms-element-bs-image-text__video"
          :poster="mediaUrl"
        >
          <source v-if="isIntersecting" :src="videoUrl" type="video/mp4" />
          <base-image :url="mediaUrl" :alt="mediaAlt" :loading="loading" />
        </video>
        <base-image
          v-else-if="media"
          :media="media"
          :alt="mediaAlt"
          :loading="loading"
          :sizes="imageSizes"
          :proxy-srcset="imageProxySrcset"
          class="cms-element-bs-image-text__image"
        />
      </cms-util-link>
      <cms-element-text
        v-if="text"
        :style="textStyle"
        :class="textClasses"
        class="cms-element-bs-image-text__text"
        :content="content"
      ></cms-element-text>
    </div>
  </div>
</template>

<script>
import CmsUtilLink from '~/components/cms/util/CmsUtilLink'
import CmsElementText from '~/components/cms/elements/CmsElementText'
import {
  getCmsLink,
  getCmsLinkTarget,
  getCmsSlotConfigProperty,
  getSmallestMediaThumbnailUrl,
  isCmsSlotActive,
} from '~/helpers'
import BaseImage from '~/components/base/image/BaseImage'

export default {
  name: 'CmsElementBsImageText',
  components: { BaseImage, CmsUtilLink, CmsElementText },
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isIntersecting: false,
    }
  },
  computed: {
    isActive() {
      return isCmsSlotActive(this.content)
    },
    text() {
      return this.content.data.content
    },
    media() {
      return this.content.data.media
    },
    mediaUrl() {
      return this.media ? getSmallestMediaThumbnailUrl(this.media, 500) : null
    },
    mediaAlt() {
      return this.content.data.media?.alt
    },
    videoUrl() {
      return this.content.data?.videoMedia?.url
    },
    isVideo() {
      return !!this.videoUrl
    },
    imageLink() {
      return getCmsLink(this.content)
    },
    imageLinkTarget() {
      return getCmsLinkTarget(this.content)
    },
    isTextFullCustom() {
      return !!getCmsSlotConfigProperty(this.content, 'textFullCustom')
    },
    textClasses() {
      return getCmsSlotConfigProperty(this.content, 'textCss')
    },
    loading() {
      return getCmsSlotConfigProperty(this.content, 'disableLazy') || false
        ? 'eager'
        : 'lazy'
    },
    imageSizes() {
      return getCmsSlotConfigProperty(this.content, 'sizes') || undefined
    },
    imageProxySrcset() {
      const srcsetSizes =
        getCmsSlotConfigProperty(this.content, 'srcsetSizes') || undefined

      return srcsetSizes
        ? srcsetSizes.split(',').map((v) => parseInt(v.trim()))
        : [400, 800, 1200]
    },
    textStyle() {
      return {
        top: getCmsSlotConfigProperty(this.content, 'posTop') || undefined,
        left: getCmsSlotConfigProperty(this.content, 'posLeft') || undefined,
        right: getCmsSlotConfigProperty(this.content, 'posRight') || undefined,
        bottom:
          getCmsSlotConfigProperty(this.content, 'posBottom') || undefined,
      }
    },
    anchor() {
      return getCmsSlotConfigProperty(this.content, 'anchor') || undefined
    },
    mediaStyle() {
      return {
        height:
          getCmsSlotConfigProperty(this.content, 'mediaHeight') || undefined,
        aspectRatio:
          getCmsSlotConfigProperty(this.content, 'ratioAspect') || undefined,
      }
    },
    classes() {
      return [
        getCmsSlotConfigProperty(this.content, 'elementCss'),
        {
          'cms-element-bs-image-text--full-widht': !!getCmsSlotConfigProperty(
            this.content,
            'fullWidth'
          ),
        },
      ]
    },
    responsiveSizeStyle() {
      const split = getCmsSlotConfigProperty(
        this.content,
        'ratioAspect'
      )?.split('/')

      if (split && split.length === 2) {
        const ratioAspect = Number(split[0]) / Number(split[1])

        return {
          paddingBottom: (1 / ratioAspect) * 100 + '%',
        }
      }

      return {}
    },
  },
  methods: {
    onIntersect(entries) {
      this.isIntersecting =
        this.isIntersecting || entries?.[0]?.intersectionRatio >= 0.5
    },
  },
}
</script>
